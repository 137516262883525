import React, { useState } from "react"
import { Link } from "gatsby"
import logo from "../assets/images/white.png"
import styled from "styled-components"
import { useTransition } from "transition-hook"
import { FiAlignJustify } from "react-icons/fi"
import { FiX } from "react-icons/fi"
import DarkMode from "../components/DarkMode"

const NavWrapper = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: black;

  @media (orientation: landscape) and (max-width: 915px) {
    height: 12vh;
  }
`

const NavLogo = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80%;
  margin: auto;

  @media (orientation: landscape) and (max-width: 915px) {
    width: 15%;
    position: absolute;
    left: 1%;
    top: 2%;
  }
`

const NavElements = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 95%;
  min-width: 60%;
  margin: 0.5rem 0rem;
  @media (orientation: landscape) and (max-width: 915px) {
    padding-top: 1rem;
    padding-left: 1.5rem;
  }
`

const StyledLink = styled(Link)`
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  color: #cbb26a;
  text-transform: uppercase;
  background-color: transparent;
  padding: 0.5rem 0.5rem;
  font-size: 0rem;
  font-family: "Roboto";
  font-weight: 400;
  letter-spacing: 0.3rem;

  :hover {
    background-color: #cbb26a;
    color: #000;
  }
`

const OpenMenu = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 75px 0 75px;
  border-color: #000000 transparent transparent transparent;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 1;
`

const Navbar = () => {
  const [onOff, setOnOff] = useState(false)
  const { stage } = useTransition(onOff, 300) // (state, timeout)

  const menuActive = {
    fontSize: stage === "enter" ? "0.8rem" : "0rem",
    border: stage === "enter" ? "1px solid #cbb26a" : "none",
    margin: "1rem .5rem 1rem .5rem",
    minWidth: "15%",
    transform: "translateY(-30%)",
    transition: ".5s linear",
  }

  return (
    <>
      <NavWrapper className="navbar">
        <div className="nav-center">
          <Link to="/" title="Higienistka Magdalena Romatowska | Home">
            <NavLogo
              src={logo}
              alt="higienistka magdalena"
              title="Higienistka Magdalena Romatowska"
            />
          </Link>
        </div>
        <NavElements
          className="nav-elements"
          style={{
            height: stage === "enter" ? "5vh" : "1vh",

            transition: ".5s linear",
          }}
        >
          <StyledLink
            to="/about"
            style={menuActive}
            title="Higienistka Magdalena Romatowska | O mnie"
          >
            o mnie
          </StyledLink>
          <StyledLink
            to="/blog"
            style={menuActive}
            title="Higienistka Magdalena Romatowska | Blog"
          >
            blog
          </StyledLink>
          <StyledLink
            to="/contact"
            style={menuActive}
            title="Higienistka Magdalena Romatowska | Kontakt"
          >
            kontakt
          </StyledLink>
        </NavElements>
        <DarkMode />
      </NavWrapper>
      <OpenMenu onClick={() => setOnOff(!onOff)}>
        {onOff ? (
          <FiX
            style={{
              fontSize: "2rem",
              color: "#cbb26a",
              position: "relative",
              transform: "translate(-50%, -150%)",
              transition: ".5s linear",
              opacity: 1,
            }}
          />
        ) : (
          <FiAlignJustify
            style={{
              fontSize: "2rem",
              color: "#cbb26a",
              position: "relative",
              transform: "translate(-50%, -150%)",
              transition: ".5s linear",
              opacity: 1,
            }}
          />
        )}
      </OpenMenu>
    </>
  )
}

export default Navbar
