import React from "react"
import styled from "styled-components"

const FooterWrapper = styled.footer`
  background-color: black;
  text-align: center;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
`

const PWrapper = styled.p`
  background-color: black;
  color: white;
`

const SpanWrapper = styled.span`
  color: #cbb26a;
`
const LinkWrapper = styled.a`
  color: #cbb26a;
  text-decoration: none;
  &:visited,
  &:active {
    color: #cbb26a;
    text-decoration: none;
  }
`

const Footer = () => {
  return (
    <FooterWrapper>
      <PWrapper>
        &copy; {new Date().getFullYear()}{" "}
        <SpanWrapper>Higienistka Magdalena</SpanWrapper>. Built with{" "}
        <LinkWrapper href="https://www.gatsbyjs.com/">Gatsby</LinkWrapper>
      </PWrapper>
    </FooterWrapper>
  )
}

export default Footer
