import React, { useState, useEffect } from "react"
import "../assets/css/main.css"
import styled from "styled-components"
import { FaLightbulb } from "react-icons/fa"

const DarkModeWrapper = styled.div`
  @media (orientation: landscape) and (max-width: 915px) {
    position: absolute;
    right: 1%;
    top: 1%;
  }
`

const DarkMode = () => {
  const [darkMode, setDarkMode] = useState(false)

  useEffect(() => {
    const json = localStorage.getItem("site-dark-mode")
    const currentMode = JSON.parse(json)
    if (currentMode) {
      setDarkMode(true)
    } else {
      setDarkMode(false)
    }
  }, [])

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark")
    } else {
      document.body.classList.remove("dark")
    }
    const json = JSON.stringify(darkMode)
    localStorage.setItem("site-dark-mode", json)
  }, [darkMode])

  return (
    <>
      <DarkModeWrapper type="button" onClick={() => setDarkMode(!darkMode)}>
        <FaLightbulb
          style={{ color: "#cbb26a", cursor: "pointer", fontSize: "2rem" }}
        />
      </DarkModeWrapper>
    </>
  )
}

export default DarkMode
